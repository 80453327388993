@import "styles/_variables.scss";

.container {
  background-color: $greyBackground;
  padding: 80px 0;
  margin: 0;

  @media (max-width: $tabletPoint) {
    padding: 56px 0;
    margin: 0 0 56px;
  }

  @media (max-width: $mobilePoint) {
    padding: 48px 0;
    margin: 0 0 33px;
  }
}

.section {
  color: $blogMainText;
  font-family: $fontFamily;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  max-width: 1108px;
  margin: 0 auto;

  @media(max-width: $tabletPoint) {
    max-width: 100%;
    padding: 0 22px;
    margin: 0;
  }

  &__title {
    text-align: center;
    font-weight: 700;
    font-size: 28px;
    line-height: 42px;
  }

  &__content {
    border-left: 1px solid $bloghrColor;
    margin: 0 auto;
    padding-left: 32px;

    @media (max-width: $mobilePoint) {
      border: none;
      padding-left: 0;
    }
  }

  &__block {
    background-color: $whiteBackground;
    border-radius: 8px;
    display: flex;
    column-gap: 24px;
    position: relative;
    padding: 24px;
    margin-bottom: 24px;
    max-width: 732px;

    &:last-of-type {
      margin-bottom: 40px;
    }

    @media (max-width: $mobilePoint) {
      border-radius: 0;
      padding: 20px;
      column-gap: 0;
      flex-direction: column;
      row-gap: 16px;
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &::before {
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      background-color: $bloghrColor;
      border-radius: 50%;
      left: -38.5px;
      top: calc(50% - 6px);

      @media (max-width: $mobilePoint) {
        display: none;
      }
    }

    &_image {
      display: flex;
      align-items: center;
    }

    &_text {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }

    &_title {
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
    }

    &_description {
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      margin: 0;
    }
  }

  &__block:first-of-type {
    margin-top: 24px;

    @media (max-width: $mobilePoint) {
      margin-top: 0;
    }
  }
}
